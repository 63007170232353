import { useParams } from "react-router-dom";
import useFeature from "./useFeature";

const useSpectrumBlock = () => {
  const { featureId } = useParams();
  const feature = useFeature({ id: featureId }, { skip: featureId == null });

  const items = [
    {
      id: "spectraArray",
      type: "spectraArray",
      data: feature?.data?.spectrum_ids,
      link: (rowData) => `/spectra/${rowData}`,
    },
    { id: "notes" },
  ];

  return items;
};

export default useSpectrumBlock;
