import Button from "../../components/Button";

const SelectivityInput = (props) => {
  console.log(">> props?.spectrum: ", props?.spectrum);
  let content;
  if (props?.side !== "both") {
    content =
      props?.spectrum[props?.side]?.blue_slashes == null ? (
        <Button
          inline
          pressed={props?.value !== "NT"}
          caption={"OK"}
          onClick={(e) => {
            e.preventDefault();
            props?.handleClick({ side: props?.side, value: "OK" });
          }}
        />
      ) : null;
  } else {
    content = (
      <>
        {" "}
        <Button
          inline
          pressed={props?.value !== "NT"}
          caption={"OK"}
          onClick={(e) => {
            e.preventDefault();
            props?.handleClick({ side: props?.side, value: "OK" });
          }}
        />
        {props?.side === "both" ? (
          <Button
            inline
            pressed={props?.value === "NT"}
            caption={"NT"}
            onClick={(e) => {
              e.preventDefault();
              props?.handleClick({ side: props?.side, value: "NT" });
            }}
          />
        ) : null}
      </>
    );
  }
  return (
    <div
      style={{
        display: "flex",
        width: "25%",
        justifyContent: "center",
      }}
    >
      {content}
    </div>
  );
};

export default SelectivityInput;
