import paramConfig from "../../../config/paramConfig";

const useTagBlock = () => {
  const items = [
    {
      id: "name",
      width: 36,
      required: true,
      link: (rowData) => `/spectra?${paramConfig.TAG}=${rowData.id}`,
    },
    {
      id: "description",
      width: 72,
    },
  ];

  return items;
};

export default useTagBlock;
