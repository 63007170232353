import useLang from "../../../hooks/useLang";
import useSpectra from "./useSpectra";
import useFeatures from "../../features/hooks/useFeatures";
import useModifierModal from "../../forms/hooks/useModifierModal";
import usePostSpectrum from "../../features/hooks/usePostSpectrum";
import useSpectrumBlock from "../../features/hooks/useSpectrumBlock";
import useTags from "../../tags/hooks/useTags";
import getOptions from "../../../utils/getOptions";
import useSearchParams from "../../../hooks/useSearchParams";
import paramConfig from "../../../config/paramConfig";

const useSpectraList = () => {
  const { getParam, setParam, removeParam } = useSearchParams();
  const selectedTag = getParam(paramConfig.TAG);
  const lang = useLang();
  const spectra = useSpectra({ tagId: selectedTag });
  const features = useFeatures();
  const tags = useTags();
  const options = getOptions(tags?.data, "name");

  const { formAttribs: spectraFormAttribs, formActions: addSpectrum } =
    useModifierModal({
      mutationHook: usePostSpectrum,
      formConfigHook: useSpectrumBlock,
    });

  let spectraData = { ...spectra?.data };
  if (features?.data != null && spectra?.data != null) {
    spectraData = spectra?.data?.map((spectrum) => {
      let featureList = spectrum?.feature_ids?.map((featureId) => {
        return features?.data?.find((feature) => feature.id === featureId);
      });
      return {
        spectrum,
        features: featureList,
      };
    });
  }

  const handleTagChange = (data) => {
    if (data?.id) {
      setParam(paramConfig.TAG, data?.id);
    } else {
      removeParam(paramConfig.TAG);
    }
  };

  const page = {
    hasAccess: spectra.hasAccess,
    header: {
      title:
        spectra?.status === "pending"
          ? lang("spectra.spectra_loading")
          : lang("spectra.title"),
      buttons: [
        {
          caption: "Filter",
          type: "dropdown",
          options,
          onChange: handleTagChange,
          value: { id: selectedTag },
        },
        {
          caption: lang("common.create"),
          icon: "plus",
          type: "modifier-modal",
          formAttribs: spectraFormAttribs,
          ...addSpectrum,
        },
      ],
    },
    paging: { ...spectra.pagination },
    items: [
      {
        id: "spectraTable",
        type: "grid",
        data: spectraData,
        config: [
          {
            id: "spectrum",
            width: 72,
            header: {
              title: lang("spectra.example"),
            },
            link: (rowData) => {
              return `/spectra/${rowData?.spectrum?.id}`;
            },
            type: "spectrum",
          },
          {
            id: "features",
            width: 72,
            header: {
              title: lang("captions.feature"),
            },
            fontSize: "small",
            linkList: (rowData) => {
              return rowData?.features?.map((feature) => ({
                id: feature?.id,
                to: `/features/${feature?.id}`,
                caption: feature?.name,
              }));
            },
            arrayLimit: 2,
          },
        ],
      },
    ],
  };

  return page;
};

export default useSpectraList;
