import { useState, useEffect } from "react";
import { lateralityFrequencies } from "../chartConfig";

const useSpectrum = ({ spectrum, onChange }) => {
  const [rightSideAttribs, setRightSideAttribs] = useState({
    onChange,
    side: "right",
  });
  const [leftSideAttribs, setLeftSideAttribs] = useState({
    onChange,
    side: "left",
  });
  const [laterality, setLaterality] = useState({});
  const [selectivity, setSelectivity] = useState({});
  const [timeStamp, setTimeStamp] = useState();
  const [selectedSeries, setSelectedSeries] = useState();
  const [deleteMode, setDeleteMode] = useState();

  const onSelectSeries = (series) => {
    setSelectedSeries((state) => {
      if (state !== series) return series;
      return undefined;
    });
  };

  const onClear = (event) => {
    event.preventDefault();
    setRightSideAttribs({ onChange, side: "right" });
    setLeftSideAttribs({ onChange, side: "left" });
    setLaterality({});
    setSelectivity({});
    setTimeStamp(Date.now());
  };

  const handleKeyDown = (event) => {
    if (event?.key === "Control") setSelectedSeries("BC");
    if (event?.key === "Alt") setDeleteMode(true);
  };

  const handleKeyUp = (event) => {
    if (event?.key === "Control") setSelectedSeries();
    if (event?.key === "Alt") setDeleteMode();
  };

  const handleSelectivityClick = ({ side, value }) => {
    setSelectivity((state) => {
      if (side !== "both") {
        if (state[side] === "NT") {
          const newState = { ...state, [side]: "OK" };
          if (
            (newState == null || newState?.right === "OK") &&
            (newState == null || newState?.left === "OK")
          ) {
            newState.both = "OK";
          }
          return newState;
        } else return { ...state, [side]: "NT", both: "NT" };
      } else {
        if (value === "OK") {
          return { right: "OK", both: "OK", left: "OK" };
        } else {
          return { right: "NT", both: "NT", left: "NT" };
        }
      }
    });
  };

  const selectivityInputs = ["right", "both", "left"].map((side) => ({
    key: side,
    side: side,
    value: selectivity[side] || "OK",
    handleClick: handleSelectivityClick,
  }));

  const handleLateralityClick = ({ f, value }) => {
    setLaterality((state) => {
      if (state[f] === value) {
        const newState = { ...state };
        delete newState[f];
        return newState;
      }
      return { ...state, [f]: value };
    });
  };

  const lateralityInputs = lateralityFrequencies.map((frequency) => ({
    key: frequency,
    f: frequency,
    value: laterality[frequency],
    caption: frequency,
    handleClick: handleLateralityClick,
  }));

  useEffect(() => {
    onChange({ selectivity });
    // eslint-disable-next-line
  }, [JSON.stringify(selectivity)]);

  useEffect(() => {
    onChange({ laterality });
    // eslint-disable-next-line
  }, [JSON.stringify(laterality)]);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
    // eslint-disable-next-line
  }, []);

  return {
    rightSideAttribs,
    leftSideAttribs,
    timeStamp,
    onClear,
    onSelectSeries,
    selectedSeries,
    deleteMode,
    setDeleteMode,
    lateralityInputs,
    selectivityInputs,
  };
};

export default useSpectrum;
