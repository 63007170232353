import { Routes, Route, Navigate } from "react-router-dom";
import { selectOrganisations } from "./features/auth/authSlice";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import PersistLogin from "./components/PersistLogin";
import Login from "./features/auth/Login";
import Cognito from "./features/auth/Cognito";
import Layout from "./components/Layout";
import FeaturesList from "./features/features/FeaturesList";
import FeaturePage from "./features/features/FeaturePage";
import ConclusionsList from "./features/conclusions/ConclusionsList";
import ConclusionPage from "./features/conclusions/ConclusionPage";
import TagsList from "./features/tags/TagsList";
import UsersList from "./features/users/UsersList";
import SpectraList from "./features/spectrum/SpectraList";
import SpectrumPage from "./features/spectrum/SpectrumPage";
import TestPage from "./features/test/TestPage";
import AssessmentsList from "./features/assesments/AssessmentsList";
import AssessmentPage from "./features/assesments/AssessmentPage";
import ParticipantsList from "./features/participants/ParticipantsList";
import ParticipantPage from "./features/participants/ParticipantPage";
import DiagnosesList from "./features/diagnoses/DiagnosesList";
import OrganisationsList from "./features/organisations/OrganisationsList";
import StaffPage from "./features/staff/StaffPage";
import useHasAccess from "./hooks/useHasAccess";

const App = () => {
  const organisations = useSelector(selectOrganisations);
  let destination;
  if (process.env.REACT_APP_DESTINATION === "ai") {
    destination = "ai";
  }
  if (process.env.REACT_APP_DESTINATION === "portal") {
    destination = "portal";
  }
  const hasAccessToFeatures = useHasAccess({ apiCall: "features.GET" });
  return (
    <>
      <Routes>
        <Route path="cognito" element={<Cognito />} />
        <Route path="login" element={<Login />} />
        <Route element={<PersistLogin />}>
          <Route element={<Layout />}>
            {destination !== "portal" && hasAccessToFeatures ? (
              <>
                <Route path="features">
                  <Route index element={<FeaturesList />} />
                  <Route path=":featureId" element={<FeaturePage />} />
                </Route>
                <Route path="conclusions">
                  <Route index element={<ConclusionsList />} />
                  <Route path=":conclusionId" element={<ConclusionPage />} />
                </Route>
                <Route path="tags" element={<TagsList />} />
                <Route path="spectra">
                  <Route index element={<SpectraList />} />
                  <Route path=":spectrumId" element={<SpectrumPage />} />
                </Route>
                <Route path="diagnoses" element={<DiagnosesList />} />
                <Route path="users" element={<UsersList />} />
                <Route path="organisations">
                  <Route index element={<OrganisationsList />} />
                </Route>
              </>
            ) : null}
            {Array.isArray(organisations) &&
              destination !== "ai" &&
              organisations.map((organisation) => (
                <Route key={organisation} path={organisation}>
                  <Route path="test" element={<TestPage />} />
                  <Route path="assessments">
                    <Route index element={<AssessmentsList />} />
                    <Route path=":assessmentId" element={<AssessmentPage />} />
                  </Route>
                  <Route path="participants">
                    <Route index element={<ParticipantsList />} />
                    <Route
                      path=":participantId"
                      element={<ParticipantPage />}
                    />
                  </Route>
                  <Route path="staff" element={<StaffPage />} />=
                  <Route
                    index
                    element={<Navigate to={"participants"} replace />}
                  />
                  <Route
                    path="*"
                    element={<Navigate to={"participants"} replace />}
                  />
                </Route>
              ))}
            {destination === "portal" ? (
              <>
                {Array.isArray(organisations) ? (
                  <>
                    <Route
                      index
                      element={
                        <Navigate
                          to={`${organisations[0]}/participants`}
                          replace
                        />
                      }
                    />
                    <Route
                      path="*"
                      element={
                        <Navigate
                          to={`${organisations[0]}/participants`}
                          replace
                        />
                      }
                    />
                  </>
                ) : (
                  <Route path="*" element={<Outlet />} />
                )}
              </>
            ) : (
              <>
                <Route index element={<Navigate to={"features"} replace />} />
                <Route
                  path="*"
                  element={<Navigate to={"features"} replace />}
                />
              </>
            )}
          </Route>
        </Route>
      </Routes>
    </>
  );
};

export default App;
