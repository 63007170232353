import AxisLabels from "./AxisLabels";
import SpectrumSide from "./SpectrumSide";
import { leftLabelWidth } from "./chartConfig";
import useGetSpectrum from "./hooks/useGetSpectrum";
import LateralityInput from "./LateralityInput";

const SpectrumPreview = ({ id, data, viewOnly, size }) => {
  const { data: spectrumDataFromApi } = useGetSpectrum({ id });
  const spectrumData = data != null ? data : spectrumDataFromApi;
  const lateralitySorted = Array.isArray(spectrumData?.laterality)
    ? [...spectrumData?.laterality]
    : [];
  lateralitySorted.sort((a, b) => {
    if (a.f < b.f) return -1;
    else if (a.f > b.f) return 1;
    return 0;
  });

  const selectivityOK =
    Array.isArray(spectrumData?.left?.blue_slashes) &&
    spectrumData?.left?.blue_slashes?.length === 0 &&
    Array.isArray(spectrumData?.right?.blue_slashes) &&
    spectrumData?.right?.blue_slashes?.length === 0;
  const selectivityNT =
    spectrumData != null &&
    (spectrumData?.left?.blue_slashes == null ||
      spectrumData?.right?.blue_slashes === null);

  return (
    <div>
      <div style={{ display: "flex", flexShrink: "0" }}>
        <SpectrumSide
          key={"RIGHT"}
          labelsOnTop
          labelsOnLeft
          data={spectrumData?.right}
          viewOnly={viewOnly}
          side={"right"}
          size={size}
        />
        {size !== "small" && (
          <div
            style={{
              position: "relative",
              width: `${leftLabelWidth}px`,
              flexShrink: 0,
            }}
          >
            {selectivityOK || selectivityNT ? (
              <h3
                style={{
                  textAlign: "center",
                  marginTop: "25px",
                  color: "var(--clr-blue-500)",
                }}
              >
                {selectivityOK ? "OK" : selectivityNT ? "NT" : ""}
              </h3>
            ) : null}
            <AxisLabels
              onLeft
              labelsOnTop
              labelsOnLeft
              oddOnly
              noPadding
              size={size}
            />
          </div>
        )}
        <SpectrumSide
          key={"LEFT"}
          data={spectrumData?.left}
          labelsOnTop
          labelsOnRight
          viewOnly={viewOnly}
          side={"left"}
          size={size}
        />
      </div>
      {lateralitySorted != null && size !== "small" ? (
        <div
          style={{
            display: "flex",
            gap: "var(--gap-base)",
            paddingTop:
              size !== "small" ? "var(--padding-section-header)" : "unset",
            paddingBottom:
              size !== "small" ? "var(--padding-section-bottom)" : "unset",
            justifyContent: "center",
          }}
        >
          {lateralitySorted.map((laterality) => (
            <LateralityInput
              key={laterality.f}
              caption={laterality.f}
              value={laterality.v}
              viewOnly
              size
            />
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default SpectrumPreview;
